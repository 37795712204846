import { Checkbox, Divider } from 'antd';
import { FC, memo, useCallback, useMemo, useState } from 'react';

import { toCamelCase } from '@/utils/toCamelCase';

import useExploreFilters from '../../../hooks/useExploreFilters';
import { Search } from '../../Search';
import VirtualizedList from '../../VirtualizedList';

type ValidKeys =
  | 'selectedManagementGroup'
  | 'selectedDomiciles'
  | 'selectedCurrencies'
  | 'selectedPlatforms';

interface Props {
  filterKey: ValidKeys;
  options: Array<{
    label: string;
    value: string;
  }>;
}

const MoreFiltersSearchWithCheckboxes = ({ filterKey, options }: Props) => {
  const [filter, setFilter] = useState('');

  const { getMutableFilters, updateTentativeFilters, tentativeFilters } =
    useExploreFilters();

  const selectedTargetValues = tentativeFilters.moreFilters?.[filterKey];

  const filteredOptions = useMemo(() => {
    return options.filter(option =>
      option.label.toLowerCase().includes(filter.toLowerCase()),
    );
  }, [options, filter]);

  const renderItem = useCallback(
    (index: number) => {
      const item = filteredOptions[index];
      return (
        <MoreFiltersItem
          label={item.label}
          value={item.value}
          moreFiltersKey={filterKey}
        />
      );
    },
    [filteredOptions, options],
  );

  return (
    <div>
      <div className="mb-2">
        <Search onChange={setFilter} input={filter} />
      </div>
      <Checkbox
        checked={filteredOptions.length === selectedTargetValues?.length}
        className="px-2"
        onChange={({ target }) => {
          const f = getMutableFilters();
          f.moreFilters = f.moreFilters ?? {};
          if (target.checked) {
            f.moreFilters[filterKey] = filteredOptions.map(o => o.value);
          } else {
            f.moreFilters[filterKey] = undefined;
          }

          updateTentativeFilters(f);
        }}
      >
        Select all
      </Checkbox>
      <Divider className="m-0" />
      <div className="px-2 mt-2 overflow-auto min-h-52">
        <VirtualizedList
          items={filteredOptions}
          renderItemByIndex={renderItem}
        />
      </div>
    </div>
  );
};

const MoreFiltersItem: FC<{
  label: string;
  value: string;
  moreFiltersKey: ValidKeys;
}> = memo(({ label, value, moreFiltersKey }) => {
  const { tentativeFilters, getMutableFilters, updateTentativeFilters } =
    useExploreFilters();
  const selectedItems = tentativeFilters.moreFilters?.[moreFiltersKey];

  return (
    <Checkbox
      data-test-id={toCamelCase(label)}
      value={value}
      checked={selectedItems?.includes(value)}
      onChange={({ target }) => {
        const f = getMutableFilters();
        f.moreFilters = f.moreFilters ?? {};
        const newItems = f.moreFilters[moreFiltersKey] ?? [];
        if (target.checked) {
          newItems.push(value);
        } else {
          newItems.splice(newItems.indexOf(value), 1);
        }
        f.moreFilters[moreFiltersKey] = newItems.length ? newItems : undefined;
        updateTentativeFilters(f);
      }}
    >
      {label}
    </Checkbox>
  );
});

export default MoreFiltersSearchWithCheckboxes;
