import {
  FundRelativeRiskMeasuresDetail,
  PageQueryParametersSortDirectionEnum,
} from '@aminsights/contract';
import { FundExploreItem } from '@aminsights/contract';
import {
  BenchmarkType,
  DATE_PERIOD_FILTER,
  EDateFilterValues,
  getFundShareClassDetailsFromArray,
  LIMIT_FUNDS_FOR_CHARTING,
  MsTimePeriod,
  WATCHLIST_TAB_KEY,
} from '@aminsights/shared';
import { LoadingOutlined } from '@ant-design/icons';
import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';

import { Dropdown } from '@/components';
import { DataTable } from '@/components/Table';
import { DataTableRenderedAt } from '@/constants/dataTableRenderedAt';
import { TABLE_UNIQUE_KEY } from '@/constants/misc';
import handleOnRow from '@/hooks/handleOnRow';
import { useInfiniteScrollV2 } from '@/hooks/useInfiniteScroll';
import useOnCheckedRow from '@/hooks/useOnCheckedRow';
import { evaluateColumns } from '@/partials/columns/evaluateColumns';
import { parseDateValue, useDatePickerContext } from '@/partials/DatePicker';
import { ESGLegend } from '@/partials/ESG/ESGLegend';
import TablesActionToast from '@/partials/Toast/TablesActionToast';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import useExploreFilters from '../hooks/useExploreFilters';

const ExploreTable: React.FCWithChild<{
  currentTab: WATCHLIST_TAB_KEY;
  activeTab: WATCHLIST_TAB_KEY;
  fetchNextPage?: () => void;
  page?: number;
  hasNextPage?: boolean;
  data: FundExploreItem[];
  loading?: boolean;
  loadingNextPage?: boolean;
  showDropdown?: boolean;
}> = ({
  currentTab,
  activeTab,
  fetchNextPage,
  hasNextPage,
  data,
  loading,
  loadingNextPage,
  showDropdown,
}) => {
  const { getMutableFilters, updateTentativeFilters, syncFilters } =
    useExploreFilters();

  const topISINs = useMemo(() => {
    const firstItems = data.slice(0, LIMIT_FUNDS_FOR_CHARTING);
    return firstItems.map(item => item.shareClassDetails.isin);
  }, [data]);

  const onSortHandler = (
    sortKey: string,
    sortDirection: PageQueryParametersSortDirectionEnum,
  ) => {
    if (sortKey === 'upsideDownsideSortKey') {
      const type: keyof Pick<
        FundRelativeRiskMeasuresDetail,
        'captureRatioTypeOne' | 'captureRatioTypeTwo'
      > =
        sortDirection === PageQueryParametersSortDirectionEnum.Asc
          ? 'captureRatioTypeTwo'
          : 'captureRatioTypeOne';

      const filters = getMutableFilters();
      filters.sortKey = `relativeRiskMeasuresDetail.${type}.${BenchmarkType.MORNINGSTAR_CATEGORY}.${timePeriod}`;
      filters.sortDirection = sortDirection;
      filters.page = 1;
      updateTentativeFilters(filters);
    } else {
      const filters = getMutableFilters();
      filters.sortDirection = sortDirection;
      filters.sortKey = sortKey;
      filters.page = 1;
      updateTentativeFilters(filters);
    }
    syncFilters();
  };
  const onRow = handleOnRow();
  const {
    checkedRows,
    setCheckedRows,
    isAllCheckboxChecked,
    setIsAllCheckboxChecked,
    onCheckedRow,
  } = useOnCheckedRow<FundExploreItem>(topISINs.length || 0);

  const { lastElementRef } = useInfiniteScrollV2(Boolean(hasNextPage), () =>
    fetchNextPage?.(),
  );

  useEffect(() => {
    if (checkedRows.length === LIMIT_FUNDS_FOR_CHARTING) {
      setIsAllCheckboxChecked(true);
    }
  }, [checkedRows]);

  const { value: datePickerValue, handleChange: setPeriod } =
    useDatePickerContext();

  const allowedPeriodValues = [
    EDateFilterValues['1YR'],
    EDateFilterValues['3YR'],
    EDateFilterValues['5YR'],
  ];

  const allowedPeriodsForDropdown = DATE_PERIOD_FILTER.filter(p =>
    allowedPeriodValues.some(apv => apv === p.value),
  );

  const period = useMemo(() => {
    if (allowedPeriodValues.includes(datePickerValue.mode)) {
      return datePickerValue.mode;
    }
    return EDateFilterValues['1YR'];
  }, [datePickerValue]);

  let timePeriod: MsTimePeriod = MsTimePeriod.ONE_YR;
  switch (datePickerValue.mode) {
    case EDateFilterValues['1YR']:
      timePeriod = MsTimePeriod.ONE_YR;
      break;
    case EDateFilterValues['3YR']:
      timePeriod = MsTimePeriod.THREE_YRS;
      break;
    case EDateFilterValues['5YR']:
      timePeriod = MsTimePeriod.FIVE_YRS;
      break;
  }

  const screenWidthMode = getScreenWidthMode();

  const [dropdownPortal, setDropdownPortal] = useState<HTMLElement | null>(
    null,
  );

  useEffect(() => {
    if (screenWidthMode[ScreenWidthEnum.MaxSm]) {
      setDropdownPortal(document.getElementById('dropdown-portal-mobile'));
    } else {
      setDropdownPortal(document.getElementById('dropdown-portal-desktop'));
    }
  }, [screenWidthMode]);

  const columns = evaluateColumns({
    currentTab,
    checkboxParams: {
      onCheckedRow,
      checkedISINs: checkedRows,
      topISINs,
      isAllCheckboxChecked,
    },
    dataTableRenderedAt: DataTableRenderedAt.Explore,
    showPrimaryShareClassIndicator: true,
    dateRange: timePeriod,
  });

  return (
    <>
      {dropdownPortal &&
        activeTab === WATCHLIST_TAB_KEY.RISK &&
        ReactDOM.createPortal(
          <>
            {showDropdown && (
              <Dropdown
                rounded
                label="1 year"
                value={period}
                onSelect={(selectedValue: EDateFilterValues) => {
                  setPeriod(parseDateValue(selectedValue), selectedValue);
                }}
                items={allowedPeriodsForDropdown}
                defaultValue={period}
              />
            )}
          </>,
          dropdownPortal,
        )}
      <DataTable
        uniqueKey={TABLE_UNIQUE_KEY}
        onSort={onSortHandler}
        loading={loading}
        columns={columns}
        data={data}
        onRow={currentTab === WATCHLIST_TAB_KEY.SUMMARY ? undefined : onRow}
        lastElementRef={lastElementRef}
      />
      {loadingNextPage && (
        <LoadingOutlined
          className="py-2"
          style={{ fontSize: 60, color: '#f0f2f5', width: '98%' }}
          spin
        />
      )}
      {currentTab === WATCHLIST_TAB_KEY.ESG && <ESGLegend />}
      {checkedRows.length > 0 && (
        <TablesActionToast
          isins={checkedRows}
          count={checkedRows.length}
          checkedRowsClassDetails={checkedRows
            .slice(0, 4)
            .map(i => getFundShareClassDetailsFromArray(data, i))}
          dataTableRenderedAt={DataTableRenderedAt.Explore}
          onClearCheckedRows={(isins: string[]) => {
            setCheckedRows(isins);
            setIsAllCheckboxChecked(false);
          }}
        />
      )}
    </>
  );
};

export default ExploreTable;
