import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ReactComponent as Padlock } from '@/assets/svg/graphic-password.svg';
import Button from '@/components/Button';
import Input from '@/components/Input';
import { SimpleAlert } from '@/components/SimpleAlert';
import {
  changePasswordFields,
  ChangePasswordFormProps,
} from '@/constants/auth';

import style from './style.module.less';

type UpdatePasswordFormProps = {
  submitting: boolean;
  onSubmit: (values: ChangePasswordFormProps) => void;
  error: string | null;
  success: boolean;
};

export const UpdatePasswordForm: React.FCWithChild<UpdatePasswordFormProps> = ({
  onSubmit,
  submitting,
  error,
  success,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty, isValid },
    trigger,
    reset,
  } = useForm<ChangePasswordFormProps>();

  useEffect(() => {
    if (success) {
      reset();
    }
  }, [success]);

  function validatePassword(value: string) {
    if (value !== watch('newPassword')) return 'Passwords do not match';
    if (value === watch('currentPassword'))
      return 'New password should be different from current.';
    return;
  }

  const renderFields = changePasswordFields.map(field => (
    <Controller
      key={field.name}
      control={control}
      name={field.name}
      rules={
        field.name === 'confirmNewPassword'
          ? { validate: validatePassword, ...field.validation }
          : field.validation
      }
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <Input
            autoFocus={field.autoFocus}
            id={field.name}
            dataTestId={`${field.name}Input`}
            type={field.type}
            name={field.name}
            label={field.label}
            enableTogglePassword={field.name.toLowerCase().includes('password')}
            onChange={onChange}
            onKeyUp={() => {
              if (['confirmNewPassword', 'newPassword'].includes(field.name)) {
                trigger();
              }
            }}
            error={error?.message}
            value={value}
            disabled={submitting}
            placeholder={field.placeholder}
            maxLength={field.maxLength}
            className={'mb-4'}
          />
          {field.name === 'currentPassword' && (
            <p className="text-left text-xs mb-6">
              New password must contain 10 or more characters. Must contain
              lower case, upper case, numbers and special characters
              (&#x21;&#64;&#35;&#36;&#37;&#94;&#38;&#42;). And contain no more
              than 2 identical sequential characters.
            </p>
          )}
        </>
      )}
    />
  ));

  const onFormSubmit = (values: ChangePasswordFormProps) => {
    onSubmit(values);
  };

  return (
    <form
      onSubmit={handleSubmit(onFormSubmit)}
      className={style['update-password__form']}
    >
      <div className={style['update-password__title-header']}>
        <span data-test-id="securitySectionTitle">Change Password</span>
      </div>
      {renderFields}
      {error && (
        <div className="mb-6">
          <SimpleAlert type="warning" message={error} showIcon closable />
        </div>
      )}
      <div className="flex flex-row justify-end">
        <Button
          data-test-id="savePasswordButton"
          disabled={submitting || !isDirty || !isValid}
          className={style['submit-btn']}
          type="primary"
          htmlType="submit"
        >
          {submitting ? 'Please wait...' : 'Save Changes'}
        </Button>
      </div>
    </form>
  );
};

interface UpdatePassNotificationProps {
  title: string;
  subtitle: string;
  label: string;
}

export const UpdatePassNotification: React.FCWithChild<
  UpdatePassNotificationProps
> = ({ title, subtitle, label }) => {
  return (
    <div className={style['update-password__notification']}>
      <Padlock />
      <h4>{title}</h4>
      <p className={style['update-password__subtitle']}>{subtitle}</p>
      <p className={style['update-password__action']}>{label}</p>
    </div>
  );
};
