import { CommentBody } from '@aminsights/contract';
import { Modal } from 'antd';
import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import Button from '@/components/Button';
import TextArea from '@/components/TextArea';
import { useCreateComment } from '@/hooks/query-hooks/internal-hooks/useManageCommentary';

interface ModalProps {
  isVisible: boolean;
  fundId?: string;
  fundName?: string;
  toggleModal: () => void;
}

const CommentaryFormModal: FC<ModalProps> = ({
  isVisible,
  fundId,
  fundName,
  toggleModal,
}) => {
  const { control, handleSubmit, reset, formState } = useForm<CommentBody>({
    mode: 'all',
  });
  const { isValid, isSubmitting } = formState;

  const createComment = useCreateComment();
  const { isLoading } = createComment;

  useEffect(() => {
    reset({ description: '', fundName });
  }, [fundName]);

  const handleSaveComment = handleSubmit(async data => {
    if (!fundId) return;

    await createComment.mutateAsync({
      fundId,
      body: data,
    });
    toggleModal();
    reset({ description: '', fundName });
  });

  const handleCloseModal = () => {
    if (isSubmitting) return;
    toggleModal();
    reset({ description: '', fundName });
  };

  return (
    <Modal
      centered={true}
      destroyOnClose
      width={960}
      open={isVisible}
      onCancel={handleCloseModal}
      title="Commentary"
      className="max-sm:full-page-modal action-modal [&_.ant-modal-body]:max-sm:h-[calc(100vh-160px)]"
      footer={[
        <Button
          className="font-medium text-sm p-0"
          size="large"
          type="link"
          key="secondary"
          onClick={handleCloseModal}
          disabled={isLoading}
        >
          Cancel
        </Button>,
        <Button
          className="h-10 w-20 font-medium p-0 m-0"
          type="primary"
          size="large"
          key="primary"
          htmlType="submit"
          disabled={!isValid}
          onClick={handleSaveComment}
          loading={isLoading}
        >
          Save
        </Button>,
      ]}
    >
      <div className="flex flex-col gap-2 h-full">
        <label className="leading-5 font-semibold" htmlFor="description">
          Description
        </label>
        <Controller
          control={control}
          name="description"
          rules={{
            required: 'Description is required',
            maxLength: {
              value: 2000,
              message: `You have hit the 2,000 character limit.
              Reduce the characters and also let us know if this is a regular problem for you.`,
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextArea
              id="description"
              name="description"
              className="max-sm:!h-full"
              value={value}
              onChange={onChange}
              autoFocus={isVisible}
              placeholder="Write something..."
              rows={8}
              error={error?.message}
              disabled={isLoading}
              style={{
                height: '150px', // Will be overwritten by the handle
              }}
            />
          )}
        />
      </div>
    </Modal>
  );
};
export default CommentaryFormModal;
