import { Sector, SectorsApi } from '@aminsights/contract';
import { useQuery } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

import { SECTORS } from '../watchlist-hooks/query-keys';

const sectorsApi = new SectorsApi(openApiConfig());

type UseSectorsError = unknown;

export const useSectors = () => {
  return useQuery<Sector[], UseSectorsError>([SECTORS], async () => {
    const response = await sectorsApi.getSectors();

    const result = response.data.sectors;

    return result;
  });
};
