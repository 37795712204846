import { Modal } from 'antd';
import cx from 'classnames';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { ReactComponent as IconLeftArrow } from '@/assets/svg/icons/icon-line-arrow.svg';
import useShareClassesByFundId from '@/hooks/query-hooks/fund-hooks/useShareClassesByFundId';
import ShareClassTable from '@/partials/shareClassTable';

import {
  generateWatchlistModalFooter,
  WatchlistModalContent,
} from '../WatchlistModal';
import {
  useWatchlistModal,
  WatchlistModalContext,
  withWatchlistModal,
} from '../WatchlistModal/context';
import style from './style.module.less';

type ShareClassModalProps = {
  isin?: string;
  fundId?: string;
  visible: boolean;
  onClose: () => void;
  fundName?: string;
};
type Display = 'shareClass' | 'addToBucket';

const ShareClassModal: React.FCWithChild<ShareClassModalProps> = ({
  isin,
  fundId,
  visible,
  onClose,
  fundName,
}) => {
  const shareClassesQuery = useShareClassesByFundId(fundId, {
    enabled: visible,
  });
  const { data, isLoading } = shareClassesQuery;

  const [currentDisplay, setCurrentDisplay] = useState<Display>('shareClass');
  const [isinsToAdd, setIsinsToAdd] = useState<string[]>([]);
  const { openNewBucketModal } = useContext(WatchlistModalContext);
  const { selectedBucket, selectBucket, assign } = useWatchlistModal({
    isins: isinsToAdd,
    onClose,
  });
  const isShareClassModal = currentDisplay === 'shareClass';

  const handleAddToBucket = (isins: string[]) => {
    setIsinsToAdd(isins);
    setCurrentDisplay('addToBucket');
  };

  const shareClassCode = useMemo(() => {
    return data?.find(v => v.shareClassDetails?.isin === isin)
      ?.shareClassDetails?.code;
  }, [data]);

  useEffect(() => {
    if (!visible) {
      setCurrentDisplay('shareClass');
      setIsinsToAdd([]);
    }
  }, [visible]);

  return (
    <Modal
      centered={true}
      title={
        isShareClassModal ? (
          'Share Classes'
        ) : (
          <div className="text-xl font-bold flex gap-x-1 items-center text-[#181920]">
            <IconLeftArrow
              onClick={() => setCurrentDisplay('shareClass')}
              className="cursor-pointer"
            />
            Add to watchlist
          </div>
        )
      }
      width={704}
      className={cx(
        isShareClassModal
          ? 'max-sm:full-page-modal information-modal [&_.ant-modal-header]:shadow-sm'
          : 'max-sm:full-page-modal action-modal [&_.ant-modal-body]:!pt-0',
      )}
      open={visible}
      onCancel={onClose}
      footer={
        !isShareClassModal
          ? generateWatchlistModalFooter({
              onAddNewBucket: openNewBucketModal,
              onAssign: assign,
              assignDisabled: !selectedBucket,
            })
          : null
      }
    >
      {isShareClassModal ? (
        <div className="text-[#545576]">
          <h4 className="font-bold text-base">{shareClassCode}</h4>
          <div>
            {/* Can't remove style here for now */}
            <div className={cx('mt-5', style['share-class-table'])}>
              <ShareClassTable
                data={data ?? undefined}
                isLoading={isLoading}
                onAddToBucket={handleAddToBucket}
                noCheckbox={false}
                defaultChecked={isinsToAdd}
              />
            </div>
          </div>
        </div>
      ) : (
        <WatchlistModalContent
          isins={isinsToAdd}
          onBucketSelect={selectBucket}
          selectedBucket={selectedBucket}
          fundName={fundName}
        />
      )}
    </Modal>
  );
};

export default withWatchlistModal(ShareClassModal);
