import { Button, Dropdown, Menu } from 'antd';
import cx from 'classnames';
import { FC, useCallback, useMemo, useState } from 'react';

import { ReactComponent as CheckActive } from '@/assets/svg/icons/icon-check-active.svg';
import { ReactComponent as Close } from '@/assets/svg/icons/icon-close.svg';
import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import { Search } from '@/pages/app/Explore/components/Search';

const dataTestId = 'sectorsDropdown';

interface SectorsDropdownProps {
  value: string;
  options: { value: string; label: string }[];
  isOptionsLoading?: boolean;
  className?: string;
  isValuePlaceholder?: boolean;
  onSelect: (value: string) => void;
  onClear?: () => void;
}

const SectorsDropdown: FC<SectorsDropdownProps> = ({
  value,
  options,
  isOptionsLoading,
  isValuePlaceholder,
  className,
  onSelect,
  onClear,
}) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [searchText, setSearchText] = useState<string>('');

  const lowerCaseSearchText = searchText.toLowerCase();

  const handleCloseDropdown = useCallback(() => {
    setIsMenuVisible(false);
    setSearchText('');
  }, []);

  const selectedOption = useMemo(
    () => options.find(option => option.value === value),
    [options, value],
  );

  return (
    <>
      <div className={cx('max-w-full overflow-hidden bg-white')}>
        <Dropdown
          destroyPopupOnHide
          className={cx(className, 'p-1')}
          open={isMenuVisible}
          overlayClassName="w-40"
          overlay={() => (
            <div className={'pt-3 bg-white rounded shadow-modal'}>
              <div className="px-2">
                <Search
                  autoFocus={isMenuVisible}
                  onChange={val => setSearchText(val || '')}
                  visible={isMenuVisible}
                  setSearchText={setSearchText}
                  input={searchText}
                />
              </div>
              <div className="py-2">
                <Menu
                  className="overflow-y-auto !px-2 rounded ~shadow-none"
                  style={{ height: 250 }}
                  items={options
                    // Filter sector options by search text
                    .filter(({ label }) =>
                      label.toLowerCase().includes(lowerCaseSearchText),
                    )
                    .map(item => ({
                      label: (
                        <span>
                          <span
                            className={cx(
                              'flex-1',
                              selectedOption?.value === item.value && 'active',
                            )}
                            data-test-id={`${dataTestId}${item.value}`}
                          >
                            {item.label}
                          </span>
                          {selectedOption?.value === item.value && (
                            <CheckActive className="icon text-sm" />
                          )}
                        </span>
                      ),
                      key: `${item.value.toString()}-${item.label}`,
                      onClick: () => {
                        onSelect(item.value);
                        setIsMenuVisible(false);
                      },
                    }))}
                />
              </div>
            </div>
          )}
          trigger={['click']}
          placement="bottomLeft"
          autoAdjustOverflow={true}
        >
          <Button
            className={cx(
              'flex gap-2 w-full h-10 px-3 group py-1 items-center text-left text-sm',
              'text-neutral border border-[#ccccd0] rounded hover:border-primary',
              { 'border-primary': isMenuVisible },
            )}
            onClick={() => setIsMenuVisible(true)}
            data-test-id={dataTestId}
            loading={isOptionsLoading}
          >
            <p
              className={cx('truncate text-sm mb-0 grow', {
                'text-[#bfbfbf]': isValuePlaceholder || !value,
              })}
              data-test-id={`${dataTestId}-selected-value`}
            >
              {selectedOption?.label || 'Select sector'}
            </p>
            {onClear && Boolean(value) && (
              <div
                onClick={e => {
                  e.stopPropagation();
                  onClear();
                }}
                className={cx(
                  'grid w-5 h-5 justify-center items-center rounded-full',
                  'hover:bg-grey-lightest group transition duration-100',
                )}
              >
                <Close className={'w-2 h-2 fill-[#516285] cursor-pointer'} />
              </div>
            )}
            <div>
              <NextArrow
                className={cx(
                  'w-2.5 h-2.5 ml-1 mt-0.5 text-xs transform rotate-90 fill-[#516285] group-hover:fill-[#007fff]',
                  { 'text-primary fill-[#007fff]': isMenuVisible },
                )}
              />
            </div>
          </Button>
        </Dropdown>
      </div>
      {isMenuVisible && (
        <div
          className="z-50 fixed top-0 left-0 w-full h-full bg-transparent"
          onClick={handleCloseDropdown}
          onKeyUp={handleCloseDropdown}
          role="button"
          tabIndex={0}
        ></div>
      )}
    </>
  );
};

export default SectorsDropdown;
