import { useSplitTreatments } from '@splitsoftware/splitio-react';
import React, { createContext, useContext, useEffect, useReducer } from 'react';

import { getConfigValue } from '@/constants';
import {
  SECTORS_WITH_CHARTS,
  TREATMENT_NAME_CLARITY,
  TREATMENT_NAME_COMPUTED_RISK_DETAILS,
  TREATMENT_NAME_FUND_DETAILS_ACTIONS,
  TREATMENT_NAME_SC_FLOW,
  TREATMENT_NAME_WEEKLY_UPDATE,
  TreatmentValues,
} from '@/constants/split';
import { useScript } from '@/utils/useScript';

export type FeatureSwitchState = {
  isInitialised: boolean;
  isClarityEnabled: boolean;
  isFundDetailsActionsTreatmentEnabled: boolean;
  isWeeklyUpdateTreatementEnabled: boolean;
  isScFlowTreatmentEnabled: boolean;
  isSectorsWithChartsEnabled: boolean;
  isComputedRiskDetailsTreatmentEnabled?: boolean;
};

const initialState: FeatureSwitchState = {
  isClarityEnabled: false,
  isInitialised: false,
  isFundDetailsActionsTreatmentEnabled: false,
  isWeeklyUpdateTreatementEnabled: false,
  isScFlowTreatmentEnabled: false,
  isSectorsWithChartsEnabled: false,
  isComputedRiskDetailsTreatmentEnabled: false,
};

export enum FEATURE_SWITCH_ACTIONS {
  SET_SPLITS = 'feature-switch:set-splits',
}

type FeatureSwitchDispatchActions = {
  type: FEATURE_SWITCH_ACTIONS;
  payload: FeatureSwitchState;
};

type FeatureSwitchContextProps = {
  state: FeatureSwitchState;
  dispatch: React.Dispatch<FeatureSwitchDispatchActions>;
};

const FeatureSwitchContext = createContext<FeatureSwitchContextProps>({
  state: initialState,
  dispatch: () => ({}),
});

const reducer = (
  state: FeatureSwitchState,
  action: FeatureSwitchDispatchActions,
): FeatureSwitchState => {
  switch (action.type) {
    case FEATURE_SWITCH_ACTIONS.SET_SPLITS: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
        isInitialised: true,
      };
    }
    default:
      return state;
  }
};

const FeatureSwitchProvider: React.FCWithChild = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { treatments, isReady } = useSplitTreatments({
    names: [
      TREATMENT_NAME_CLARITY,
      TREATMENT_NAME_FUND_DETAILS_ACTIONS,
      TREATMENT_NAME_WEEKLY_UPDATE,
      TREATMENT_NAME_SC_FLOW,
      SECTORS_WITH_CHARTS,
      TREATMENT_NAME_COMPUTED_RISK_DETAILS,
    ],
  });

  useEffect(() => {
    if (isReady && !state.isInitialised) {
      const clarityTreatment = treatments[TREATMENT_NAME_CLARITY];
      const fundDetailsActionsTreatment =
        treatments[TREATMENT_NAME_FUND_DETAILS_ACTIONS];
      const weeklyUpdateTreatment = treatments[TREATMENT_NAME_WEEKLY_UPDATE];
      const scFlowTreatment = treatments[TREATMENT_NAME_SC_FLOW];
      const sctorsWithCharts = treatments[SECTORS_WITH_CHARTS];
      const computedRiskDetailsTreatment =
        treatments[TREATMENT_NAME_COMPUTED_RISK_DETAILS];

      dispatch({
        type: FEATURE_SWITCH_ACTIONS.SET_SPLITS,
        payload: {
          isInitialised: true,
          isClarityEnabled: clarityTreatment?.treatment === TreatmentValues.On,
          isFundDetailsActionsTreatmentEnabled:
            fundDetailsActionsTreatment?.treatment === TreatmentValues.On,
          isWeeklyUpdateTreatementEnabled:
            weeklyUpdateTreatment?.treatment === TreatmentValues.On,
          isScFlowTreatmentEnabled:
            scFlowTreatment?.treatment === TreatmentValues.On,
          isSectorsWithChartsEnabled:
            sctorsWithCharts?.treatment === TreatmentValues.On,
          isComputedRiskDetailsTreatmentEnabled:
            computedRiskDetailsTreatment?.treatment === TreatmentValues.On,
        },
      });
    }
  }, [isReady, treatments]);

  useScript(
    `/clarity.${getConfigValue('REACT_APP_NODE_ENV')}.js`,
    'ms_clarity',
    !state.isClarityEnabled,
  );

  return (
    <FeatureSwitchContext.Provider value={{ state, dispatch }}>
      {state.isInitialised ? <>{children}</> : null}
    </FeatureSwitchContext.Provider>
  );
};

export { FeatureSwitchProvider };

export const useFeatureSwitchContext = (): FeatureSwitchContextProps => {
  return useContext(FeatureSwitchContext);
};

export default FeatureSwitchContext;
