import React from 'react';

import LegendCard, { LegendCardProps } from '..';

interface ChartsLegendCardProps extends LegendCardProps {
  value: string;
}

const ChartsLegendCard: React.FCWithChild<ChartsLegendCardProps> = ({
  color,
  label,
  tooltip,
  value,
  isBenchmark,
  isFeatured,
  isActive,
  isSector,
  idForFundDetailsLink,
  onRemove,
  id,
  targetLink,
  date,
  isPortfolio,
}) => {
  return (
    <LegendCard
      color={color}
      tooltip={tooltip}
      idForFundDetailsLink={idForFundDetailsLink}
      label={label}
      isActive={isActive}
      isBenchmark={isBenchmark}
      isPortfolio={isPortfolio}
      isFeatured={isFeatured}
      isSector={isSector}
      onRemove={onRemove}
      id={id}
      targetLink={targetLink}
      date={date}
    >
      <p className="text-xs font-bold text-darkest">{value}</p>
    </LegendCard>
  );
};

export default ChartsLegendCard;
