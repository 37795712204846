import { Fund, FundsApi } from '@aminsights/contract';
import React, { useEffect, useState } from 'react';

import { APP_ACTIONS, LIMIT_FUNDS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import BasePageWithMetadata from '@/pages/app/BasePageWithMetadata';
import useProvideHelp from '@/pages/app/Help/useProvideHelp';
import SectionBackButton from '@/partials/Sections/SectionBackButton';
import { openApiConfig } from '@/utils';
import { openJiraForm } from '@/utils/jiraForm';

import AssignFundsToPortfolioComponent from './AssignFundsToPortfolioComponent';
import {
  FailedStatus,
  UnavailableISINsItems,
  UploadModalInfoItems,
} from './types';
import UploadFunds from './UploadFunds';
import UploadModal from './UploadModal';

const ImportFunds: React.FCWithChild = () => {
  const { sendUnavailableIsinsEmail } = useProvideHelp();
  const { dispatch: dispatchApp } = useAppContext();
  const [isFundsImported, setIsFundsImported] = useState<boolean>(false);
  const [fileList, setFileList] = useState<string[]>([]);
  const [importedFundsList, setImportedFundsList] = useState<Fund[]>([]);
  const [unavailableISINsList, setUnavailableISINsList] = useState<
    UnavailableISINsItems[]
  >([]);
  const [isins, setIsins] = useState<string[]>([]);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);
  const [failedStatus, setFailedStatus] = useState<FailedStatus>();
  const [uploadModalInfo, setUploadModalInfo] = useState<UploadModalInfoItems>({
    title: '',
    description: '',
    unavailableISINsList: [],
  });

  const handleUploadModalOpen = () => {
    setIsUploadModalOpen(prev => !prev);
  };

  useEffect(() => {
    let count = 0;
    const mappedIsins = isins
      .map(isin => {
        const isinExist = importedFundsList.some(
          fund => isin === fund.shareClassDetails.isin,
        );

        return {
          rowNumber: !isinExist ? (count += 1) : 0,
          isin: !isinExist ? isin : '',
        };
      })
      .filter(f => f.isin);
    setUnavailableISINsList(mappedIsins);
  }, [importedFundsList.length, isins.length]);

  useEffect(() => {
    let modalInfo: UploadModalInfoItems = {
      title: '',
      description: '',
      unavailableISINsList: [],
    };

    switch (failedStatus) {
      case FailedStatus.FILE_EMPTY:
        modalInfo = {
          title: 'Upload Failed',
          description: 'Your file is empty. Please add ISINs.',
          unavailableISINsList: [],
          failedStatus: failedStatus,
        };
        setIsUploadModalOpen(true);
        break;
      case FailedStatus.FUND_LIMIT:
        modalInfo = {
          title: 'Upload Failed',
          description: (
            <p>
              You are trying to upload too many funds. The maximum is 352 funds,
              so please remove some of the necessary number and try uploading
              again. For further assistance, you may contact our{' '}
              <a
                onClick={() => {
                  openJiraForm();
                }}
              >
                support team
              </a>
            </p>
          ),
          unavailableISINsList: [],
          failedStatus: failedStatus,
        };
        setIsUploadModalOpen(true);
        break;
      case FailedStatus.INTERNET_CONNECTION:
        modalInfo = {
          title: 'Upload Failed',
          description:
            'An error occurred while uploading this file. Please check your internet connection and try again.',
          unavailableISINsList: [],
          failedStatus: failedStatus,
        };
        setIsUploadModalOpen(true);
        break;
      case FailedStatus.ISINS_NOT_FOUND:
        const title = `${
          unavailableISINsList && unavailableISINsList.length > 1
            ? `${unavailableISINsList.length} ISINS`
            : `${unavailableISINsList.length} ISIN`
        } not found`;
        modalInfo = {
          title,
          description: (
            <p>
              These ISINs are not in our database - we suggest you copy the list
              for your records. We have been notified and will endeavour to have
              them added, but for further assistance you can contact our{' '}
              <a
                onClick={() => {
                  openJiraForm();
                }}
              >
                support team
              </a>
              .
            </p>
          ),
          unavailableISINsList: unavailableISINsList,
          failedStatus: failedStatus,
        };

        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: title,
          actionName: 'View Details',
          onClick: () => setIsUploadModalOpen(true),
        });

        break;
      default:
        break;
    }
    setUploadModalInfo(modalInfo);
  }, [failedStatus, unavailableISINsList]);

  useEffect(() => {
    const fundsApi = new FundsApi(openApiConfig());
    if (isins && isins.length > 0) {
      if (isins.length > LIMIT_FUNDS) {
        setFailedStatus(FailedStatus.FUND_LIMIT);
      } else {
        fundsApi
          .getMultipleFunds(isins)
          .then(response => {
            if (isins.length !== response.data.length) {
              setFailedStatus(FailedStatus.ISINS_NOT_FOUND);
              setImportedFundsList(response.data);
            } else if (response.data.length > 0) {
              setImportedFundsList(response.data);
              setIsFundsImported(true);
            }
          })
          .catch(() => {
            setFailedStatus(FailedStatus.INTERNET_CONNECTION);
          });
      }
      setIsins(isins);
    }
  }, [isins]);

  useEffect(() => {
    if (
      failedStatus === FailedStatus.ISINS_NOT_FOUND &&
      unavailableISINsList &&
      unavailableISINsList.length
    ) {
      sendUnavailableIsinsEmail({
        isins: unavailableISINsList.map(isin => isin.isin),
      });
    }
  }, [unavailableISINsList, failedStatus]);

  return (
    <BasePageWithMetadata title={'Import Funds'}>
      <SectionBackButton previousLabel={'Back'} />
      <section className="flex w-full p-4 flex-col gap-y-6 bg-white max-lg:min-h-screen lg:pb-10">
        <h1 className="text-xl font-bold text-darkest">
          Add funds/trusts to watchlist
        </h1>
        <div className="flex flex-1 flex-col">
          {isFundsImported || failedStatus === FailedStatus.ISINS_NOT_FOUND ? (
            <AssignFundsToPortfolioComponent
              importedFundsList={importedFundsList || []}
              areISINsUnavailable={
                unavailableISINsList && unavailableISINsList.length > 0
              }
              onModalOpen={(isOpen: boolean) => {
                if (isOpen) {
                  setIsUploadModalOpen(isOpen);
                }
              }}
            />
          ) : (
            <UploadFunds
              onFundsUploadFailure={(status: FailedStatus) => {
                setFailedStatus(status);
              }}
              onFundsUploadSuccess={(isins: string[], filenames: string[]) => {
                setFileList(filenames);
                setIsins(isins);
              }}
            />
          )}
        </div>
        <UploadModal
          isSuccess={isFundsImported}
          fileNames={fileList || []}
          isVisible={isUploadModalOpen}
          toggleModal={handleUploadModalOpen}
          failedStatusModalInfo={uploadModalInfo}
        />
      </section>
    </BasePageWithMetadata>
  );
};

export default ImportFunds;
