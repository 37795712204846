import { Material, MaterialBody } from '@aminsights/contract';
import { Modal } from 'antd';
import cx from 'classnames';
import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Input } from '@/components';
import Button from '@/components/Button';
import {
  useCreateMaterial,
  useUpdateMaterial,
} from '@/hooks/query-hooks/internal-hooks/useManageMaterials';

interface ModalProps {
  isVisible: boolean;
  initialValue?: Material;
  fundId?: string;
  onCloseModal: () => void;
}

const formFields = [
  {
    name: 'text' as const,
    label: 'Text',
    placeholder: 'Enter text to display',
    validation: {
      required: 'Text is required',
    },
    autoFocus: true,
  },
  {
    name: 'link' as const,
    label: 'Link',
    placeholder: 'Enter URL',
    validation: {
      required: 'Link is required',
      pattern: {
        value:
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/i,
        message: 'Please enter a valid URL',
      },
    },
  },
];

const MaterialFormModal: FC<ModalProps> = ({
  isVisible,
  initialValue,
  fundId,
  onCloseModal,
}) => {
  const { control, handleSubmit, reset, formState, watch } =
    useForm<MaterialBody>();
  const createMaterial = useCreateMaterial();
  const updateMaterial = useUpdateMaterial();

  const { isValid, isSubmitted, isSubmitting } = formState;
  const textWatched = watch('text');
  const linkWatched = watch('link');

  const isLoading = createMaterial.isLoading || updateMaterial.isLoading;

  useEffect(() => {
    if (!initialValue) return;
    const { text, link } = initialValue;
    reset({ text, link });
  }, [initialValue]);

  const handleSaveMaterial = handleSubmit(async data => {
    if (!fundId) return;

    if (initialValue?._id) {
      await updateMaterial.mutateAsync({
        fundId,
        materialId: initialValue._id,
        body: data,
      });
    } else {
      await createMaterial.mutateAsync({ fundId, body: data });
    }

    onCloseModal();
  });

  const isNewMaterial = !initialValue?._id;

  const handleCloseModal = () => {
    if (isSubmitting) return;
    reset({ text: '', link: '' });
    onCloseModal();
  };

  return (
    <Modal
      centered={true}
      destroyOnClose
      width={560}
      open={isVisible}
      onCancel={handleCloseModal}
      className="max-sm:full-page-modal action-modal"
      title={`${isNewMaterial ? 'Add' : 'Edit'} link`}
      footer={[
        <Button
          className="font-medium text-sm p-0"
          size="large"
          type="link"
          key="secondary"
          disabled={isLoading}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>,
        <Button
          className={cx('h-10 font-medium m-0', {
            'w-20': isNewMaterial,
            'w-[136px]': !isNewMaterial,
          })}
          type="primary"
          size="large"
          key="primary"
          htmlType="submit"
          disabled={!linkWatched || !textWatched || (!isValid && isSubmitted)}
          onClick={handleSaveMaterial}
          loading={isLoading}
        >
          {isNewMaterial ? 'Add' : 'Save changes'}
        </Button>,
      ]}
    >
      <div className="flex flex-col gap-6">
        <form className="flex flex-col">
          {formFields.map(field => (
            <Controller
              key={field.name}
              control={control}
              name={field.name}
              rules={field.validation}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <div className="flex flex-col gap-1">
                  <label htmlFor={field.name} className="leading-5">
                    {field.label}
                  </label>
                  <Input
                    autoFocus={field.autoFocus}
                    type="text"
                    id={field.name}
                    name={field.name}
                    value={value}
                    placeholder={field.placeholder}
                    onChange={onChange}
                    error={error?.message}
                    className={'mb-4'}
                  />
                </div>
              )}
            />
          ))}
        </form>
      </div>
    </Modal>
  );
};
export default MaterialFormModal;
