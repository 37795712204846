import { Categories } from '@aminsights/contract';

import { minifiedParamKeysMap } from '../constants/exploreFilters';

export const getExploreCategoryLink = (
  label: string,
  categories: Categories[],
) => {
  const categoryIndex = categories.findIndex(ca => ca.label === label);
  const key = minifiedParamKeysMap.categories;
  if (categoryIndex < 0) {
    return '';
  }
  return `/explore?${key}=${categoryIndex}&focs=true`;
};
