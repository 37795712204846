import {
  BenchmarkGroup as BenchmarkOptionGroupEnum,
  BenchmarkOption,
} from '@aminsights/contract';
import { Button, Divider, Dropdown, Menu } from 'antd';
import cx from 'classnames';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { ReactComponent as CheckActive } from '@/assets/svg/icons/icon-check-active.svg';
import { ReactComponent as Close } from '@/assets/svg/icons/icon-close.svg';
import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import { Checkbox as MultiCheckBox } from '@/components/Checkbox';
import { DropdownItem } from '@/components/Dropdown/shared';
import { Search } from '@/pages/app/Explore/components/Search';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import style from './style.module.less';

export interface BenchmarksDropdownRef {
  close?: () => void;
}

interface BenchmarksDropdownProps {
  placeholder: string;
  value: string;
  benchmarkOptions: Array<BenchmarkOption>;
  className?: string;
  dropdownKey?: string;
  dataTestId?: string;
  excludedValues?: Array<string>;
  disabled?: boolean;
  hideCustomOptions?: boolean;
  onSelect: (
    value: string,
    group: BenchmarkOptionGroupEnum | undefined,
  ) => void;
  onClear?: () => void;
  isOptionsLoading?: boolean;
}

const BenchmarksDropdown = forwardRef<
  BenchmarksDropdownRef,
  BenchmarksDropdownProps
>(
  (
    {
      placeholder,
      value,
      benchmarkOptions,
      className,
      dropdownKey,
      dataTestId,
      excludedValues,
      disabled,
      hideCustomOptions,
      onSelect,
      onClear,
      isOptionsLoading,
    },
    ref,
  ) => {
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [activeOption, setActiveOption] = useState<
      DropdownItem | undefined
    >();
    const [searchText, setSearchText] = useState<string>('');
    const [selectedGroups, setSelectedGroups] = useState<
      Array<BenchmarkOptionGroupEnum>
    >([]);

    useImperativeHandle(ref, () => ({
      close: () => setIsMenuVisible(false),
    }));

    const optionsFilteredByGroup = selectedGroups.length
      ? benchmarkOptions.filter(item => selectedGroups.includes(item.group))
      : benchmarkOptions;

    const lowerCaseSearchText = searchText.toLowerCase();

    const options = optionsFilteredByGroup
      .map(({ name, id }) => ({
        label: name,
        value: id,
      }))
      // Filter benchmark options by search text
      .filter(({ label }) => label.toLowerCase().includes(lowerCaseSearchText))
      // Filter benchmark options by excluded values
      .filter(item => !excludedValues?.includes(item.value));

    const groupOptions = [
      { label: 'Equities', value: BenchmarkOptionGroupEnum.Equity },
      { label: 'Cash', value: BenchmarkOptionGroupEnum.Cash },
      { label: 'Bonds', value: BenchmarkOptionGroupEnum.Bonds },
      ...(!hideCustomOptions
        ? [{ label: 'Custom', value: BenchmarkOptionGroupEnum.Custom }]
        : []),
    ];

    useEffect(() => {
      if (isOptionsLoading) return;

      const newActiveOption = options.find(
        ({ value: itemValue }) => itemValue === value,
      );
      setActiveOption(newActiveOption);
    }, [value, isOptionsLoading]);

    const screenWidthMode = getScreenWidthMode();
    const isSmallMobile = screenWidthMode[ScreenWidthEnum.MaxSm];

    const mobileStyles = {
      left: '0',
      width: '100vw',
      minWidth: '439px',
    };

    return (
      <>
        <div className={style['benchmarks-dropdown']}>
          <Dropdown
            destroyPopupOnHide
            className={cx(className, 'p-1')}
            open={isMenuVisible}
            overlayStyle={isSmallMobile ? mobileStyles : { maxWidth: '358px' }}
            overlay={() => (
              <div className={'pt-2 bg-white rounded shadow-modal'}>
                <div className="flex flex-col gap-2 px-2 pt-2">
                  <Search
                    autoFocus={isMenuVisible}
                    onChange={val => setSearchText(val || '')}
                    key={dropdownKey}
                    visible={isMenuVisible}
                    setSearchText={setSearchText}
                    input={searchText}
                  />
                  <MultiCheckBox
                    data={groupOptions}
                    value={selectedGroups}
                    onChange={setSelectedGroups}
                    direction="row"
                  />
                  <Divider className="m-0" />
                </div>
                <Menu
                  className={style['benchmarks-dropdown-menu']}
                  style={{ height: 250 }}
                  items={options
                    .filter(i => !excludedValues?.some(val => val === i.value))
                    .map(item => ({
                      label: (
                        <span>
                          <span
                            className={
                              activeOption?.value === item.value
                                ? 'flex-1 active'
                                : 'flex-1'
                            }
                            data-test-id={`${dataTestId}Value${item.value}`}
                          >
                            {item.label}
                          </span>
                          {activeOption?.value === item.value && (
                            <CheckActive className="icon text-sm" />
                          )}
                        </span>
                      ),
                      key: `${item.value.toString()}-${item.label}`,
                      onClick: () => {
                        setActiveOption(item);
                        const group = benchmarkOptions.find(
                          bo => bo.id === item.value,
                        )?.group;
                        onSelect(item.value, group);
                        setIsMenuVisible(false);
                      },
                    }))}
                />
              </div>
            )}
            trigger={['click']}
            placement="bottomLeft"
            autoAdjustOverflow={true}
            disabled={disabled}
          >
            <Button
              className={cx(style['dropdown-button'], {
                [style['dropdown-button-open']]: isMenuVisible,
              })}
              onClick={() => setIsMenuVisible(true)}
              data-test-id={dataTestId}
              loading={isOptionsLoading}
            >
              <p
                className={cx(
                  style['dropdown-label'],
                  !activeOption?.label && style['is-placeholder'],
                )}
                data-test-id={`${dataTestId}SelectedLabel`}
              >
                {activeOption?.label || placeholder}
              </p>
              {onClear && Boolean(value) && (
                <div
                  onClick={e => {
                    e.stopPropagation();
                    setActiveOption(undefined);
                    onClear();
                  }}
                  className={cx(style['close-button'])}
                >
                  <Close
                    className={cx(style['close-icon'], 'cursor-pointer')}
                  />
                </div>
              )}
              <div>
                <NextArrow
                  className={cx('icon', style['dropdown-icon-caret'], '!w-2.5')}
                />
              </div>
            </Button>
          </Dropdown>
        </div>
        {isMenuVisible && (
          <div
            className={style['dropdown-overlay']}
            onClick={() => {
              setIsMenuVisible(false);
              setSelectedGroups([]);
              setSearchText('');
            }}
            onKeyUp={() => {
              setIsMenuVisible(false);
              setSelectedGroups([]);
              setSearchText('');
            }}
            role="button"
            tabIndex={0}
          ></div>
        )}
      </>
    );
  },
);

export default BenchmarksDropdown;
