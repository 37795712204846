// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oythtHnrvBALeAtpRk43 {\n  overflow: hidden;\n  word-break: break-word;\n  width: calc(100% - 32px);\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n.oythtHnrvBALeAtpRk43 p {\n  text-overflow: ellipsis;\n  display: block;\n  white-space: nowrap;\n  overflow: hidden;\n}\n.EGYaYJGYVU0vjiYnFI84 {\n  width: 100%;\n  height: auto;\n}\n.Bkr5c1z3_SBWa3aHNE9Y {\n  display: flex;\n  justify-content: space-between;\n}\n.Bkr5c1z3_SBWa3aHNE9Y p {\n  overflow: hidden;\n  word-break: break-word;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n.Pibtc9IbjUrrpx4LxInH .EGYaYJGYVU0vjiYnFI84 {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/partials/LegendCards/style.module.less"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,sBAAA;EACA,wBAAA;EACA,mBAAA;EACA,uBAAA;AADF;AAJA;EAOI,uBAAA;EACA,cAAA;EACA,mBAAA;EACA,gBAAA;AAAJ;AAIA;EACE,WAAA;EACA,YAAA;AAFF;AAKA;EACE,aAAA;EACA,8BAAA;AAHF;AAMA;EAEI,gBAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AALJ;AASA;EAEI,WAAA;AARJ","sourcesContent":["@import 'src/less/partials/_variables.less';\n\n.label {\n  overflow: hidden;\n  word-break: break-word;\n  width: calc(100% - 32px);\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  p {\n    text-overflow: ellipsis;\n    display: block;\n    white-space: nowrap;\n    overflow: hidden;\n  }\n}\n\n.legendCard {\n  width: 100%;\n  height: auto;\n}\n\n.fundDetails {\n  display: flex;\n  justify-content: space-between;\n}\n\n.fundDetails {\n  p {\n    overflow: hidden;\n    word-break: break-word;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n  }\n}\n\n.fundDetailsLink {\n  .legendCard {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "oythtHnrvBALeAtpRk43",
	"legendCard": "EGYaYJGYVU0vjiYnFI84",
	"fundDetails": "Bkr5c1z3_SBWa3aHNE9Y",
	"fundDetailsLink": "Pibtc9IbjUrrpx4LxInH"
};
export default ___CSS_LOADER_EXPORT___;
