import {
  BucketsApi,
  ResourceUser,
  ResourceUserRoleEnum,
  ShareBucketRequestBody,
} from '@aminsights/contract';
import { AUTH0_NAMESPACE } from '@aminsights/shared';
import { useMutation } from '@tanstack/react-query';
import { Divider, Modal, RefSelectProps, Select } from 'antd';
import cx from 'classnames';
import { differenceWith, isEqual, uniqBy } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';

import { ReactComponent as IconLeftArrow } from '@/assets/svg/icons/icon-line-arrow.svg';
import Button from '@/components/Button';
import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { AxiosAuthContext } from '@/context/AxiosAuthContext';
import useOrganizationUsers from '@/hooks/query-hooks/organization/useOrganizationUsers';
import { WATCHLIST } from '@/hooks/query-hooks/watchlist-hooks/query-keys';
import { useCurrentWatchlist } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import useAutoFocusRef from '@/hooks/useAutoFocusRef';
import RoleSelect from '@/partials/User/RoleSelect';
import {
  ResourceUserExtendedActions,
  ResourceUserRoleOptions,
} from '@/partials/User/types';
import UsersList from '@/partials/User/UsersList';
import queryClient from '@/queryClient';
import { openApiConfig } from '@/utils';
import { ListItemUser } from '@/utils/user';

import styles from './style.module.less';

interface ModalProps {
  visible: boolean;
  onClose: () => void;
  className?: string;
  title: string;
  bucketId: string;
}

interface SearchOption {
  label: string;
  value: string;
}

const ShareModalBucket = ({
  bucketId,
  visible,
  onClose,
  className,
  title,
}: ModalProps) => {
  const [users, setUsers] = useState<string[]>([]);
  const [managedUsers, setManagedUsers] = useState<ResourceUser[]>([]);
  const [userRole, setUserRole] = useState<ResourceUserRoleEnum>(
    ResourceUserRoleEnum.Editor,
  );
  const [options, setOptions] = useState<SearchOption[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const authContext = useContext(AxiosAuthContext);
  const appContext = useAppContext();
  const currentWatchlist = useCurrentWatchlist();
  const bucketsApi = new BucketsApi(openApiConfig());
  const tenantId =
    authContext.state.decodedToken?.[`${AUTH0_NAMESPACE}/tenant_id`];
  const searchAutofocusRef = useAutoFocusRef<RefSelectProps>([visible]);

  const { data: usersData } = useOrganizationUsers(tenantId);

  const { mutate: addUsersToBucket, isLoading } = useMutation(
    (body: ShareBucketRequestBody) => bucketsApi.shareBucket(body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([WATCHLIST]);
        onClose();
        const text = hasManagedUsersChange
          ? 'Successfully updated access.'
          : 'Successfully shared bucket.';
        appContext.dispatch({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text },
        });
      },
    },
  );
  const currentBucket = currentWatchlist.data?.buckets.find(
    b => b.id === bucketId,
  );
  const currentBucketUsers =
    currentBucket?.users?.filter(
      u => u.id !== authContext.state.decodedToken?.sub,
    ) || [];

  const usersWithAccess: ListItemUser[] = useMemo(() => {
    if (usersData?.length && managedUsers?.length) {
      return managedUsers
        .map(bucketUser => {
          const user = usersData.find(
            orgUser => orgUser.user_id === bucketUser.id,
          );
          return (
            user &&
            user.user_id !== authContext.state.decodedToken?.sub && {
              id: user.user_id,
              name: user.name,
              email: user.email,
              role: bucketUser.role,
            }
          );
        })
        .filter(Boolean) as ListItemUser[];
    }
    return [];
  }, [usersData?.length, managedUsers, visible]);

  const isBucketShared = Boolean(usersWithAccess?.length);
  const isModalActive = Boolean(searchTerm) || Boolean(users.length);
  const hasManagedUsersChange = useMemo(() => {
    const difference = differenceWith(
      currentBucketUsers,
      managedUsers,
      isEqual,
    );
    return Boolean(difference.length);
  }, [currentBucketUsers, managedUsers]);

  const handleSearchTermChange = (e: string) => {
    if (e.includes(',')) {
      setUsers(prev => [...prev, e.replace(',', '')]);
      setSearchTerm('');
      return;
    }
    setSearchTerm(e);
  };

  const resetState = () => {
    setUsers([]);
    setSearchTerm('');
    setManagedUsers([]);
  };

  const handleShareClick = () => {
    addUsersToBucket({
      bucketId,
      users: uniqBy(
        [
          ...users.map(u => ({ id: u, role: userRole })),
          ...(managedUsers || []),
        ],
        'id',
      ),
    });
  };

  const handleUpdateUser = (id: string, role: ResourceUserRoleOptions) => {
    if (role === ResourceUserExtendedActions.Remove) {
      setManagedUsers(prev => prev.filter(u => u.id !== id));
      return;
    }
    setManagedUsers(prev =>
      prev.map(u => {
        if (u.id === id) {
          return { ...u, role };
        }
        return u;
      }),
    );
  };

  useEffect(() => {
    setSearchTerm('');
  }, [users.length]);

  useEffect(() => {
    setOptions(
      (usersData || [])
        .filter(
          u =>
            u.user_id !== authContext.state.decodedToken?.sub &&
            !(currentBucket?.users || []).find(bu => bu.id === u.user_id),
        )
        .map(u => ({
          label: u.name,
          value: u.user_id,
        })),
    );
  }, [usersData?.length, currentBucket]);

  useEffect(() => {
    if (!visible) {
      resetState();
    }
  }, [visible]);

  useEffect(() => {
    if (currentBucketUsers.length && visible) {
      setManagedUsers(currentBucketUsers);
    }
  }, [currentBucketUsers.length, visible]);

  return (
    <Modal
      width={600}
      className={cx('max-sm:full-page-modal information-modal', className)}
      onCancel={onClose}
      open={visible}
      title={
        <div
          className={cx(
            'grid-rows-2 gap-y-2 grid',
            isModalActive && 'grid-cols-[30px,_1fr]',
          )}
        >
          {isModalActive && (
            <div className="row-span-2">
              <IconLeftArrow onClick={resetState} className="cursor-pointer" />
            </div>
          )}
          <h3 className="text-xl font-bold">Share '{title}'</h3>
          <p className="font-normal text-sm text-[#72737e]">
            Recipients will get access to this bucket.
          </p>
        </div>
      }
      footer={[
        <Button
          size="large"
          type="link"
          key="secondary"
          className={cx(['text-sm font-medium'])}
          onClick={onClose}
          disabled={isLoading}
        >
          Cancel
        </Button>,
        <Button
          size="large"
          type="primary"
          className="font-medium h-9 rounded bg-[#0072e6] border-[#0072e6] m-0"
          disabled={isLoading || !(users.length || hasManagedUsersChange)}
          key="primary"
          onClick={handleShareClick}
          loading={isLoading}
        >
          {isBucketShared || hasManagedUsersChange ? 'Save' : 'Share'}
        </Button>,
      ]}
    >
      <div className="flex gap-x-2 gap-y-3 flex-col sm:flex-row">
        <div className="flex-1 flex flex-col">
          <Select
            ref={searchAutofocusRef}
            mode="multiple"
            value={users}
            onChange={setUsers}
            onSearch={handleSearchTermChange}
            // Can't use tailwind here, might need to actually set styles for the select itself
            className={styles['select-users']}
            searchValue={searchTerm}
            placeholder="Ex. John, Jennifer,..."
            options={options}
            filterOption={(_, opt) => {
              return Boolean(
                opt?.label?.toLowerCase().includes(searchTerm.toLowerCase()),
              );
            }}
            suffixIcon={<></>}
          />
          <p className="text-[#72737e] font-normal text-xs mt-1">
            Share to one or more recipients, separated by commas.
          </p>
        </div>
        <div className="shrink-0">
          {isModalActive && (
            <RoleSelect value={userRole} onChange={setUserRole} />
          )}
        </div>
      </div>

      {isBucketShared && (
        <>
          <Divider />
          <div className="flex flex-col gap-y-2">
            <h4 className="font-semibold text-base">People with access</h4>
            <UsersList
              users={usersWithAccess}
              onUserUpdate={handleUpdateUser}
            />
          </div>
        </>
      )}
    </Modal>
  );
};

export default ShareModalBucket;
