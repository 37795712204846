import { isInvestmentTrust } from '@aminsights/shared';
import React, { useMemo } from 'react';

import {
  useCurrentBucketId,
  useCurrentWatchlist,
} from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import AbsoluteChartTitle from '@/partials/Charts/absolute/AbsoluteChartTitle';
import AbsoluteChartV2 from '@/partials/Charts/absolute/AbsoluteChartV2';
import { RipsReturnTypeProvider } from '@/partials/Charts/Context';
import PremiumDiscountChartV2 from '@/partials/Charts/premium-discount/PremiumDiscountChartV2';
import RelativeChartTitle from '@/partials/Charts/relative/RelativeChartTitle';
import RelativeChartV2 from '@/partials/Charts/relative/RelativeChartV2';
import RiskReturnScatterPlotV2 from '@/partials/ScatterPlots/risk-return-scatter-plot/RiskReturnScatterPlotV2';

import style from './style.module.less';

export const ChartsTab: React.FCWithChild = () => {
  const currentWatchlist = useCurrentWatchlist();
  const buckets = currentWatchlist.data?.buckets;
  const currentBucketId = useCurrentBucketId();

  const currentBucket = buckets?.find(b => b.id === currentBucketId);
  const isins = useMemo(() => {
    return (
      currentBucket?.funds
        ?.filter(fund => fund.isin !== undefined)
        .map(fund => fund.isin) || []
    );
  }, [currentBucket?.name]);

  const investmentTrustIsins = useMemo(() => {
    if (currentWatchlist?.data) {
      const result = currentBucket?.funds
        ?.filter(fund => isInvestmentTrust(fund?.legalStructure))
        .map(f => f.isin);
      return result ?? [];
    }
    return [];
  }, [currentWatchlist]);

  const hasInvestmentTrust = investmentTrustIsins.length !== 0;

  const orderedFunds = currentBucket?.funds ?? [];
  const featuredIsins = orderedFunds.filter(f => f.isFeatured).map(f => f.isin);

  return (
    <RipsReturnTypeProvider>
      <div className={style.chartsTabContainer}>
        {(isins.length !== 0 || currentBucket?.indexSecId) && (
          <RiskReturnScatterPlotV2
            isins={isins}
            featuredIsins={featuredIsins}
            benchmarkId={currentBucket?.indexSecId}
            hasInvestmentTrust={hasInvestmentTrust}
            // showDropdown={true}
          />
        )}
        <AbsoluteChartTitle
          hasInvestmentTrust={hasInvestmentTrust}
          showDropdown={true}
        />
        <AbsoluteChartV2
          isins={isins}
          benchmarkId={currentBucket?.indexSecId}
          sectorId={currentBucket?.sector}
          hasInvestmentTrust={hasInvestmentTrust}
          showDropdown={true}
          featuredIsins={featuredIsins}
        />
        {isins.length !== 0 && currentBucket?.indexSecId && (
          <>
            <div className={style.divider} />
            <RelativeChartTitle
              hasInvestmentTrust={hasInvestmentTrust}
              benchmarkName={currentBucket.index}
              showDropdown={true}
            />
            <RelativeChartV2
              isins={isins}
              benchmarkId={currentBucket.indexSecId}
              hasInvestmentTrust={hasInvestmentTrust}
              showDropdown={true}
              featuredIsins={featuredIsins}
            />
          </>
        )}
        {hasInvestmentTrust && (
          <PremiumDiscountChartV2
            isins={investmentTrustIsins}
            allIsins={isins}
            showFundLinks={true}
          />
        )}
      </div>
    </RipsReturnTypeProvider>
  );
};
