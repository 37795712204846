import { Modal } from 'antd';
import cx from 'classnames';
import React from 'react';

import { ReactComponent as InfoWarningRed } from '@/assets/svg/icons/icon-warning-red.svg';
import Button from '@/components/Button';
import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';

type ModalProps = {
  modalInfo: {
    title?: string;
    description?: string | React.ReactNode;
    primaryActionLabel: string;
    primaryActionDisabled?: boolean;
    secondaryActionLabel?: string;
    succesMessage?: string;
    errorMessage?: string;
  };
  isVisible: boolean;
  className?: string;
  toggleModal: () => void;
  onConfirm: () => Promise<any | void> | void;
  loading?: boolean;
  children?: React.ReactNode;
  width?: number;
  withWarningIcon?: boolean;
  destroyOnClose?: boolean;
};

const ConfirmationModalDanger: React.FCWithChild<ModalProps> = ({
  modalInfo,
  isVisible,
  className,
  loading,
  toggleModal,
  onConfirm,
  children,
  width = 400,
  withWarningIcon = true,
  destroyOnClose,
}) => {
  const { dispatch: dispatchApp } = useAppContext();

  const onClick = () => {
    onConfirm()
      ?.then(() => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: modalInfo.succesMessage || '' },
        });
      })
      .catch(() => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: modalInfo.errorMessage || '',
        });
      });
    toggleModal();
  };
  return (
    <Modal
      centered={true}
      width={width}
      open={isVisible}
      onCancel={toggleModal}
      className={cx(
        'action-modal',
        'action-modal-confirmation',
        'action-modal-confirmation-danger',
        className,
      )}
      destroyOnClose={destroyOnClose}
      title={
        <div className="flex gap-x-2">
          {withWarningIcon && (
            <InfoWarningRed className="h-[22px] mt-0.5 w-[22px] shrink-0" />
          )}
          <div className="text-xl font-bold text-neutral-900">
            {modalInfo.title}
          </div>
        </div>
      }
      footer={[
        <Button
          size="large"
          type="link"
          key="secondary"
          disabled={loading}
          onClick={toggleModal}
          data-test-id="modalDeleteBucketCancelButton"
        >
          {modalInfo.secondaryActionLabel || 'Cancel'}
        </Button>,
        <Button
          danger
          size="large"
          onClick={onClick}
          key="primary"
          loading={loading}
          disabled={loading || modalInfo.primaryActionDisabled}
          data-test-id="modalDeleteBucketConfirmButton"
          className="m-0 !bg-[#E50E0B] !border-[#E50E0B]"
        >
          {modalInfo.primaryActionLabel}
        </Button>,
      ]}
    >
      <div className="text-base font-normal leading-6 text-[#313341]">
        {modalInfo.description}
      </div>
      {children}
    </Modal>
  );
};

export default ConfirmationModalDanger;
