export const TREATMENT_NAME_CLARITY = 'clarity';
export const TREATMENT_NAME_FUND_DETAILS_ACTIONS = 'fund-details-actions';
export const TREATMENT_NAME_WEEKLY_UPDATE = 'weekly-update';
export const TREATMENT_NAME_SC_FLOW = 'sc-flow';
export const SECTORS_WITH_CHARTS = 'sectors-and-charts';
export const TREATMENT_NAME_COMPUTED_RISK_DETAILS = 'computed-risk-details';

export enum TreatmentValues {
  On = 'on',
  Off = 'off',
  Control = 'control',
}
